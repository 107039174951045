import React from 'react';
import {Link} from '@reach/router';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { navigate } from 'gatsby';
import scrollToElement from 'scroll-to-element';

import Button from '../Button';

const Header = (props) => {
  const navToContact = () => {
    navigate('/Contact');
  };
  const navToHome = (e, target)  => {
    if (typeof window !== 'undefined') {

      // First, are we on the home page?
      // If so, let's scroll to the desired block,
      // which was passed in as an onClick method on our <Link />.
      // If an event was also passed, we'll preventDefault()
      if (window.location.pathname === '/') {
        if (e) e.preventDefault()
        scrollToElement(target, {
          offset: -95, // Offset a fixed header if you please
          duration: 1000,
        })
      } else {
        navigate('/')
        if (e) e.preventDefault()
        scrollToElement(target, {
          offset: -95, // Offset a fixed header if you please
          duration: 1000,
        })
      }

    }
  };

  return (
    <header className="sticky top-0 bg-white shadow">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <div className="flex items-center text-2xl"  onClick={() => navigate('/')}>
          Lynx Consulting
        </div>
        <div className="flex mt-4 sm:mt-0">
          <Link
              onClick={e => navToHome(e, '#features')}
              to={'/'}
          >
            Process
          </Link>
          <Link
              style={{marginLeft: 10}}
              onClick={e => navToHome(e, '#services')}
              to={'/'}
          >
            Services
          </Link>
        </div>
        <div onClick={navToContact} className="hidden md:block">
          <Button className="text-sm">Contact us</Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
